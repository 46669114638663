







import { Vue, Component } from 'vue-property-decorator'
import Index from '@/pages/Index.vue'

@Component({
  components: { Index },
})
export default class Stage extends Vue {}
