
















































import {Component, Vue, Prop} from 'vue-property-decorator'
import ProjectCardRow from "./ProjectCardRow.vue"

@Component({
  components: {
    ProjectCardRow
  },
})

export default class ProjectCard extends Vue {
  @Prop()
  private project!: any
  private avatarColor = '#274a7f'

  goTo(): void {
    window.location.href = 'https://financing.exporo.de' + this.project.url
  }

  /*
  * This method should be deleted once all projects are updated.
  * Old, not updated, projects have the url without the https//.
  * The updated projects have the url complete.
  */
  temporalUrlFormatter(url: string): string | null {
    if (!url) {
      return null
    } else if (url.startsWith('https://')) {
      return url
    } else {
      return 'https://' + url
    }
  }
}

