












import { Component, Vue } from 'vue-property-decorator'
import Stage from '@/components/Stage/Stage.vue'
import ClosedProjects from '@/components/ClosedProjects/ClosedProjects.vue'
import { Educts, Region } from '@exporo/educts'
import { PlatformApp } from '@exporo/educts-component-platform-app'
import {Route} from 'node_modules/@exporo/educts-component-region/dist/types'

    @Component({
      components: {
        Stage,
        ClosedProjects,
      },
    })

export default class Index extends Vue {
      appType: Educts.Brand = Educts.Brand.Financing
      region: Region = Region.Germany

      mounted() {
        (this.$children[0] as PlatformApp).routes.de.main.routes = (this.$children[0] as PlatformApp)
          .routes.de.main.routes.filter((route: Route) => {
            return !route.url?.includes('projekte')
        })
      }
}

