














import {Component, Vue} from 'vue-property-decorator'
// eslint-disable-next-line no-unused-vars
import axios, {AxiosResponse} from 'axios'
import ProjectCard from '../ProjectCard/ProjectCard.vue'
import {Container} from '@exporo/educts-component-grid'

@Component({
  components: {
    ProjectCard,
    Container,
  },
})

export default class ClosedProjects extends Vue {
  private closedProjects: any[] = []
  private isFetchingData = false
  private numberOfRequestsMade = 0
  private totalProjects = 0
  private numberOfProjectsPerFetch = 9
  private windowWidth = window.innerWidth

  async fetchClosedProjects(): Promise<AxiosResponse | null> {
    try {
      this.isFetchingData = true
      const response =
          axios
              .get(`https://read.financing.exporo.io/v1/projects/closed?take=${this.numberOfProjectsPerFetch}&skip=${this.numberOfProjectsPerFetch * this.numberOfRequestsMade}`)
      this.numberOfRequestsMade++
      this.isFetchingData = false
      return response
    } catch (e) {
      console.log(e)
      this.isFetchingData = false
      return null
    }
  }

  updateClosedProjects(response: AxiosResponse) {
    this.closedProjects = [...this.closedProjects, ...response.data.closedProjects]
    this.totalProjects = response.data.total
  }

  shouldFetch(bottomOfWindow: boolean): boolean {
    return bottomOfWindow && this.numberOfRequestsMade * this.numberOfProjectsPerFetch < this.totalProjects && !this.isFetchingData
  }

  async fetchOrIgnore() {
    const footerHeight = document.documentElement.getElementsByTagName('footer')[0].clientHeight
    let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) > (document.documentElement.offsetHeight - footerHeight)
    if (this.shouldFetch(bottomOfWindow)) {
      const closedProjects = await this.fetchClosedProjects()
      if (closedProjects) this.updateClosedProjects(closedProjects)
    }
  }

  async mounted() {
    const closedProjects = await this.fetchClosedProjects()
    if (closedProjects) this.updateClosedProjects(closedProjects)
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
    document.addEventListener('scroll', this.fetchOrIgnore)
  }
}

