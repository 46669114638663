

































import { Component, Vue } from 'vue-property-decorator'
import { Container } from '@exporo/educts-component-grid'

    @Component({
      components: {
        Container,
      },
    })

export default class Stage extends Vue {
        private ismobile = false

        // eslint-disable-next-line class-methods-use-this
        mounted(): void {
          if (window.innerWidth <= 960) {
            this.ismobile = true
          }
        }
}

