import Vue from 'vue'
import axios from 'axios'
import vuetify from './plugins/vuetify'
// @ts-ignore
import educts from './plugins/educts'
import App from './App.vue'

Vue.prototype.$axios = axios
Vue.config.productionTip = false

new Vue({
// @ts-ignore
  vuetify,
  // @ts-ignore
  educts,
  render: (h) => h(App),
}).$mount('#app')
